/* Dashboard css */

.Dashboard{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh; 
    padding: 20px;
}
.DashHeader{
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px 4px 12px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
}
.AppFooter{
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid rgba(0,0,0, 0.15);
     
}
.SideMenuAndPageContent{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid rgba(0,0,0, 0.05);
}
.SideMenu{
    height: 100%; 
}
.PageContent{
    padding-left: 12px;
}